import {
  AssetCard,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Colours,
  ContributionLogo,
  CopyOrShare,
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm,
  EmergencySavingsLogo,
  FynbosIcon,
  Icon,
  Label,
  Money,
  Progress,
  ProgressIndicator,
  Rings,
  Router,
  Slider,
  Switch,
  TFSALogo,
  TextField,
  dateFormat,
  timeFormat
} from '@/Components'
import { MarketingLayout } from '@/Layouts'
import { ParallaxText } from '@/Layouts/GetStartedLayout'
import { AllanGray, FNB, OneNvest, Satrix, Sygnia, TenX } from '@/Layouts/Logos'
import { cn } from '@/Lib'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import * as SliderPrimitive from '@radix-ui/react-slider'
import {
  AnimatePresence,
  motion,
  stagger,
  useAnimate,
  usePresence,
  useScroll,
  useTransform
} from 'framer-motion'
import {
  ChangeEventHandler,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Toaster } from 'sonner'

Page.layout = (page: ReactNode) => <MarketingLayout>{page}</MarketingLayout>

export default function Page() {
  return (
    <div className='w-full'>
      <Hero />
      <LogoBanner />
      <BlueBanner />
      <FynbosMethodHeader />
      <Platform />
      <JustFuckingStart />
      <EmergencySavingsHeader />
      <EmergencySavingsAssess />
      <EmergencySavingsAutomate />
      <EmergencySavingsTrack />
      <EmergencySavingsRemind />
      <TaxFreeHeader />
      <TaxFreeLong />
      <TaxFreeBest />
      <TaxFreeGuard />
      <StrongFoundations />
      <Pricing />
    </div>
  )
}

function Hero() {
  const [scope, animate] = useAnimate()

  const [percentages, setPercentages] = useState({
    total: 8000,
    es: 3300,
    tfsa: 4700
  })

  useEffect(() => {
    animate([
      [
        'li',
        { width: '0' },
        {
          duration: 0
        }
      ],
      'initial-load',
      [
        '#progress-bar-es',
        { width: `${percentages.es || 0}%` },
        {
          at: 'initial-load',
          duration: 0.3
        }
      ],
      [
        '#progress-bar-tfsa',
        { width: `${percentages.tfsa || 0}%` },
        {
          at: 'initial-load',
          duration: 0.3
        }
      ]
    ]).then(() =>
      setPercentages({
        total: 15000,
        es: 10000,
        tfsa: 5000
      })
    )
  }, [animate, percentages.es, percentages.tfsa])
  return (
    <section className='flex min-h-[calc(100svh-300px)] w-full items-center bg-slate-50 p-4 md:min-h-[calc(100dvh-320px)] lg:min-h-[calc(100dvh-320px)]'>
      <div className='mx-auto flex w-full max-w-5xl flex-col sm:flex-row'>
        <div className='contents justify-center sm:flex sm:flex-col'>
          <h1 className='flex flex-col text-6xl font-black uppercase leading-none text-strong'>
            Investing you&apos;ll actually love
          </h1>
          <p className='mt-4 max-w-prose text-2xl'>
            No jargon. No advisor fees. No bullsh*t.
          </p>
          <Router
            href={route('login')}
            className='mt-6 flex w-full items-center rounded-full bg-slate-200 p-1 hover:bg-slate-300 sm:mt-10 sm:max-w-max lg:p-2'
          >
            <span className='mx-auto text-2xl font-bold uppercase leading-none text-strong sm:ml-12 sm:mr-8 lg:text-2xl'>
              Start investing
            </span>
            <div className='flex items-center justify-center rounded-full bg-green-400 p-6'>
              <Icon className='size-8 text-3xl leading-[30px] text-white'>
                arrow_right_alt
              </Icon>
            </div>
          </Router>
        </div>
        <div className='hidden flex-col justify-center gap-4 lg:flex'>
          <Card role='presentation' className='w-full max-w-xl gap-4 px-2 py-4'>
            <h5 className='px-2 text-xl font-medium text-strong'>Portfolio</h5>
            <Progress value={percentages.total} max={1} className='my-2 bg-nav'>
              <ProgressIndicator className='overflow-clip bg-nav'>
                <div ref={scope} className='flex h-full w-full '>
                  <motion.div
                    id='progress-bar-es'
                    style={{ width: `0` }}
                    className='h-full bg-green-400'
                  />
                  <motion.div
                    id='progress-bar-tfsa'
                    style={{ width: `0` }}
                    className='h-full bg-sky-400'
                  />
                </div>
              </ProgressIndicator>
            </Progress>
            <DescriptionList className='px-2 text-lg'>
              <DescriptionTerm className='flex items-center gap-2'>
                Emergency savings
                <span className='block size-2 rounded-full bg-green-400' />
              </DescriptionTerm>
              <DescriptionDetails>
                <Money value={percentages.es} />
              </DescriptionDetails>

              <DescriptionTerm className='flex items-center gap-2'>
                Tax-free investments
                <span className='block size-2 rounded-full bg-sky-400' />
              </DescriptionTerm>
              <DescriptionDetails>
                <Money value={percentages.tfsa} />
              </DescriptionDetails>
              <DescriptionTerm className='font-medium'>Total</DescriptionTerm>
              <DescriptionDetails>
                <Money value={percentages.total} />
              </DescriptionDetails>
            </DescriptionList>
          </Card>
          <Card role='presentation' className='w-full max-w-xl gap-4 px-2 py-4'>
            <h5 className='px-2 text-xl font-medium text-strong'>
              Contributions
            </h5>
            <CardContent className='flex gap-2'>
              <Rings
                size='size-24'
                backgroundColour={cn('text-slate-100')}
                primary={{
                  colour: 'text-orange-400',
                  percentage: 1
                }}
              >
                <div className='absolute flex flex-col items-center justify-center'>
                  <ContributionLogo />
                </div>
              </Rings>
              <div className='flex flex-col gap-2 text-lg'>
                Set up recurring contributions or start your portfolio with a
                single deposit.
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </section>
  )
}

function LogoBanner() {
  return (
    <section className='bg-white py-10'>
      <div className='mx-auto flex w-full sm:hidden'>
        <ParallaxText baseVelocity={-2}>
          <OneNvest />
          <AllanGray />
          <FNB />
          <Sygnia />
          <TenX />
          <Satrix />
        </ParallaxText>
      </div>
      <div className='mx-auto hidden w-full max-w-5xl justify-center gap-6 text-pretty text-center sm:flex'>
        <OneNvest />
        <AllanGray />
        <FNB />
        <Sygnia />
        <TenX />
        <Satrix />
      </div>
    </section>
  )
}

function BlueBanner() {
  return (
    <section className='bg-blue-600 p-4 py-20'>
      <div className='mx-auto flex w-full max-w-5xl flex-col justify-center gap-6 text-pretty text-center'>
        <h3 className='flex flex-col text-2xl font-bold uppercase text-inverted sm:text-4xl sm:leading-10'>
          Fynbos helps young South Africans take control of their financial
          future by making investing simple and accessible.
        </h3>
      </div>
    </section>
  )
}

function FynbosMethodHeader() {
  return (
    <section className='p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col justify-center gap-10 text-pretty'>
        <FynbosIcon className='h-16 sm:h-24' />
        <h3 className='flex flex-col text-center text-5xl font-black uppercase leading-none text-strong sm:text-7xl sm:leading-none'>
          <span>the fynbos</span>
          <span>method</span>
        </h3>
        <p className='mx-auto max-w-prose text-center text-2xl'>
          We&apos;ve built a proven investment strategy (based on low fees, tax
          optimisation, and a long term focus) directly into our platform.
          Simplicity, transparency, automation but still full control and
          visibility.
        </p>
        <p className='mx-auto max-w-prose text-center text-2xl'>
          Every cent you spend on anything more complex is money wasted.
        </p>
      </div>
    </section>
  )
}

function Platform() {
  const intervalRef = useRef<NodeJS.Timeout>()
  const [tabValue, setTabValue] = useState<string>('tab1')
  const [dirty, setDirty] = useState<boolean>(false)

  const tabs: Record<string, Record<string, string>> = useMemo(() => {
    return {
      tab1: {
        title: 'Make regular contributions',
        description:
          'Making regular contributions to your investments ensures consistent growth and maximizes the power of compounding over time.'
      },
      tab2: {
        title: 'Build a solid emergency savings fund',
        description:
          'Protect yourself from unexpected expenses and keep your long-term investments safe during a crisis.'
      },
      tab3: {
        title: 'Maximise your tax free investments early',
        description:
          'Starting early with your tax-free investments ensures you maximise the benefit of compounding growth by paying no tax.'
      },
      tab4: {
        title: 'Automations',
        description:
          'Automations ensure consistent investing and easy portfolio management, keeping you on track effortlessly.'
      }
    }
  }, [])

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (!dirty) {
        let nextTab = parseInt(tabValue.substring(3)) + 1
        if (nextTab % 5 == 0) nextTab = 1
        setTabValue(`tab${nextTab}`)
      }
    }, 5000)

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [dirty, tabValue])

  const _onTabChange = useCallback((value: string) => {
    setTabValue(value)
    setDirty(true)
  }, [])

  return (
    <section>
      <div className='grid min-h-[30rem] w-full grid-cols-2 justify-center sm:min-h-max'>
        <AccordionPrimitive.Root
          type='single'
          value={tabValue}
          onValueChange={_onTabChange}
          className='col-span-2 ml-auto flex max-w-lg flex-col gap-6 py-8 pl-4 pr-24 md:col-span-1 md:pl-4'
        >
          {['tab1', 'tab2', 'tab3', 'tab4'].map((tab) => (
            <AccordionPrimitive.Item
              key={tab}
              value={tab}
              className='border-l-4 border-slate-200 pl-4 transition-colors duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-focus data-[state=open]:border-blue-500'
            >
              <AccordionPrimitive.Header>
                <AccordionPrimitive.Trigger className='w-full text-start text-2xl font-medium text-strong'>
                  {tabs[tab].title}
                </AccordionPrimitive.Trigger>
              </AccordionPrimitive.Header>
              <AccordionPrimitive.Content className='overflow-hidden transition-all data-[state=closed]:animate-accordionUp data-[state=open]:animate-accordionDown'>
                <span className='pt-2'>{tabs[tab].description}</span>
              </AccordionPrimitive.Content>
            </AccordionPrimitive.Item>
          ))}
        </AccordionPrimitive.Root>
        {/*Tabs Content */}
        <div
          className={cn(
            'relative col-span-1 hidden h-[50rem] w-full overflow-hidden px-12 transition-colors duration-700 md:block lg:pl-24',
            tabValue == 'tab1' && 'bg-orange-100',
            tabValue == 'tab2' && 'bg-green-100',
            tabValue == 'tab3' && 'bg-sky-100',
            tabValue == 'tab4' && 'bg-purple-100'
          )}
        >
          <Toaster expand position='top-right' offset={16} />
          <AnimatePresence mode='wait'>
            {tabValue == 'tab1' && (
              <PlatformTab1 key='content-tab1' setDirty={setDirty} />
            )}
            {tabValue == 'tab2' && <PlatformTab2 key='content-tab2' />}
            {tabValue == 'tab3' && <PlatformTab3 key='content-tab3' />}
            {tabValue == 'tab4' && <PlatformTab4 key='content-tab4' />}
          </AnimatePresence>
        </div>
      </div>
    </section>
  )
}

function PlatformTab1({
  setDirty
}: {
  setDirty: Dispatch<SetStateAction<boolean>>
}) {
  const [isPresent, safeToRemove] = usePresence()
  const [scope, animate] = useAnimate()

  useEffect(() => {
    if (isPresent) {
      const loadingAnimation = () => {
        animate([
          [
            '#card',
            { y: -20, opacity: 0 },
            {
              duration: 0
            }
          ],
          [
            '#card',
            { y: 0, opacity: 1 },
            {
              delay: stagger(0.1),
              duration: 0.3,
              ease: [0.4, 0, 0.2, 1]
            }
          ]
        ])
      }
      loadingAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          '#card',
          { y: 20, opacity: 0 },
          {
            delay: stagger(0.1),
            duration: 0.3,
            ease: [0.4, 0, 0.2, 1]
          }
        )
        safeToRemove()
      }
      exitAnimation().then()
    }
  }, [animate, isPresent, safeToRemove, scope])

  return (
    <div ref={scope} className='flex h-full flex-col justify-center'>
      <Card id='card' className='max-w-96'>
        <CardHeader className='flex items-center justify-start gap-4'>
          <ContributionLogo className='size-12' />
          <CardTitle>What are contributions?</CardTitle>
        </CardHeader>
        <CardContent>
          Contributions are regular or once-off deposits you make into your
          Fynbos portfolio.
        </CardContent>
      </Card>
      <Card id='card' className='mt-20 max-w-96'>
        <DebitOrderForm setDirty={setDirty} />
      </Card>
      <Card id='card' className='mt-4 max-w-96'>
        <CardHeader>
          <CardTitle>Once-off contributions</CardTitle>
        </CardHeader>

        <CardContent>
          Use your unique reference every time you make a manual contribution
          via EFT.
        </CardContent>
        <div className='flex w-full flex-col'>
          <Label className='mt-0'>Unique reference</Label>
          <CopyOrShare
            className='flex justify-between rounded-xl bg-nav p-3 hover:bg-nav-active'
            copyContent='https://fynbos.money/login'
          >
            FYN1234
          </CopyOrShare>
        </div>
      </Card>
    </div>
  )
}

function DebitOrderForm({
  setDirty
}: {
  setDirty: Dispatch<SetStateAction<boolean>>
}) {
  const [paused, setPaused] = useState<boolean>(true)

  const _onChangePaused = useCallback<(checked: boolean) => void>(
    (checked) => {
      setPaused(checked)
      setDirty(true)
    },
    [setDirty]
  )

  const debitStatus: ReactNode = useMemo(() => {
    const today = new Date()
    const currentDay = today.getDate()
    const currentYear = today.getFullYear()
    const currentMonth = today.getMonth()

    const deadline = new Date(
      currentYear,
      currentMonth,
      currentDay + 3,
      12,
      0,
      0,
      0
    )
    const target = new Date(currentYear, currentMonth, currentDay + 4)

    if (!paused) {
      return (
        <span>
          Your debit order is <span className='font-medium'>paused</span>.
          Re-enable it before{' '}
          <span className='font-medium'>{timeFormat(deadline)}</span> on{' '}
          <span className='font-medium'>{dateFormat(deadline)}</span> to resume
          on <span className='font-medium'>{dateFormat(target)}</span>.
        </span>
      )
    }

    return (
      <span>
        Your next debit order of <Money className='font-medium' value={500} />{' '}
        will run on <span className='font-medium'>{dateFormat(target)}</span>,
        from your bank account ending{' '}
        <span className='font-medium'>{4431}</span>.
      </span>
    )
  }, [paused])

  return (
    <>
      <CardHeader className='items-center justify-between'>
        <CardTitle>Debit order</CardTitle>
        <Switch
          id='contribution-paused'
          checked={paused}
          onCheckedChange={_onChangePaused}
        />
        <label htmlFor='contribution-paused' className='sr-only'>
          Debit order active
        </label>
      </CardHeader>
      <CardContent>{debitStatus}</CardContent>
    </>
  )
}

function PlatformTab2() {
  const [isPresent, safeToRemove] = usePresence()
  const [scope, animate] = useAnimate()

  const percentages = useMemo(() => {
    const recommendedSavings = 20000
    const external = 5000
    const fynbosSavings = 10000
    const total = 15000

    return {
      total: (total / recommendedSavings) * 100,
      external: (external / total) * 100,
      fynbos: (fynbosSavings / total) * 100
    }
  }, [])

  useEffect(() => {
    if (isPresent) {
      const loadingAnimation = () => {
        animate([
          [
            '#card',
            { y: -20, opacity: 0 },
            {
              duration: 0
            }
          ],
          [
            '#card',
            { y: 0, opacity: 1 },
            {
              duration: 0.3,
              ease: [0.4, 0, 0.2, 1]
            }
          ],
          [
            'li',
            { width: '0' },
            {
              duration: 0
            }
          ],
          'initial-load',
          [
            '#progress-bar-fynbos',
            { width: `${percentages.fynbos || 0}%` },
            {
              at: 'initial-load',
              duration: 0.3
            }
          ],
          [
            '#progress-bar-external',
            { width: `${percentages.external || 0}%` },
            {
              at: 'initial-load',
              duration: 0.3
            }
          ]
        ])
      }
      loadingAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          '#card',
          { y: 20, opacity: 0 },
          {
            duration: 0.3,
            ease: [0.4, 0, 0.2, 1]
          }
        )
        safeToRemove()
      }
      exitAnimation().then()
    }
  }, [
    animate,
    isPresent,
    percentages.external,
    percentages.fynbos,
    safeToRemove,
    scope
  ])

  return (
    <div ref={scope} className='flex h-full flex-col justify-center'>
      <Card id='card' className='max-w-96'>
        <CardHeader>
          <CardTitle>Current savings</CardTitle>
        </CardHeader>
        <Progress value={percentages.total} className='mt-4 bg-nav'>
          <ProgressIndicator className='overflow-clip bg-nav'>
            <div ref={scope} className='flex h-full w-full '>
              <motion.div
                id='progress-bar-fynbos'
                style={{ width: `0%` }}
                className='h-full bg-green-400'
              />
              <motion.div
                id='progress-bar-external'
                style={{ width: `0%` }}
                className='h-full bg-green-200'
              />
            </div>
          </ProgressIndicator>
        </Progress>
        <CardContent>
          <DescriptionList>
            <DescriptionTerm className='flex items-center gap-2'>
              Emergency savings{' '}
              <span className='block size-2 rounded-full bg-green-400' />
            </DescriptionTerm>
            <DescriptionDetails>
              <Money className='font-medium' value={10000} />
            </DescriptionDetails>
            <DescriptionTerm className='flex items-center gap-2'>
              Saved elsewhere{' '}
              <span className='block size-2 rounded-full bg-green-200' />
            </DescriptionTerm>
            <DescriptionDetails>
              <Money className='font-medium' value={5000} />
            </DescriptionDetails>
            <DescriptionTerm className='font-medium'>Total</DescriptionTerm>
            <DescriptionDetails>
              <Money className='font-medium' value={15000} />
            </DescriptionDetails>
          </DescriptionList>
        </CardContent>
        <AssetCard assetCode='AGMF' chipColor={Colours.green} />
      </Card>
    </div>
  )
}

function PlatformTab3() {
  const [isPresent, safeToRemove] = usePresence()
  const [scope, animate] = useAnimate()

  useEffect(() => {
    if (isPresent) {
      const loadingAnimation = () => {
        animate([
          [
            '#card',
            { y: -20, opacity: 0 },
            {
              duration: 0
            }
          ],
          [
            '#card',
            { y: 0, opacity: 1 },
            {
              duration: 0.3,
              ease: [0.4, 0, 0.2, 1]
            }
          ]
        ])
      }
      loadingAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          '#card',
          { y: 20, opacity: 0 },
          {
            duration: 0.3,
            ease: [0.4, 0, 0.2, 1]
          }
        )
        safeToRemove()
      }
      exitAnimation().then()
    }
  }, [animate, isPresent, safeToRemove, scope])

  const rings = useMemo(() => {
    return {
      primary: {
        percentage: 1 / 3,
        colour: 'text-sky-400'
      }
    }
  }, [])

  return (
    <div ref={scope} className='flex h-full flex-col justify-center'>
      <Card id='card' className='max-w-96'>
        <CardHeader>
          <CardTitle>Contributions progress</CardTitle>
        </CardHeader>
        <div className='mb-8 mt-5 flex w-full flex-col items-center justify-center gap-1 rounded-xl'>
          <Rings
            size='h-24 w-24'
            backgroundColour={'text-slate-100'}
            primary={rings.primary}
          >
            <div className='absolute flex flex-col items-center justify-center text-sky-400'>
              <TFSALogo />
            </div>
          </Rings>
          <span className='text-4xl font-medium text-sky-500'>
            <Money value={12_000} />
          </span>
          <span className='font-medium text-medium'>
            of <Money value={36_000} />
          </span>
        </div>
      </Card>
    </div>
  )
}

function PlatformTab4() {
  const [isPresent, safeToRemove] = usePresence()
  const [scope, animate] = useAnimate()

  useEffect(() => {
    if (isPresent) {
      const loadingAnimation = () => {
        animate([
          [
            '#card',
            { y: -20, opacity: 0 },
            {
              duration: 0
            }
          ],
          [
            '#card',
            { y: 0, opacity: 1 },
            {
              delay: stagger(0.1),
              duration: 0.3,
              ease: [0.4, 0, 0.2, 1]
            }
          ]
        ])
      }
      loadingAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          '#card',
          { y: 20, opacity: 0 },
          {
            delay: stagger(0.1),
            duration: 0.3,
            ease: [0.4, 0, 0.2, 1]
          }
        )
        safeToRemove()
      }
      exitAnimation().then()
    }
  }, [animate, isPresent, safeToRemove, scope])

  return (
    <div ref={scope} className='flex h-full flex-col justify-center'>
      <Card id='card' className='max-w-96'>
        <CardContent className='flex w-full gap-4'>
          <Icon>vertical_align_top</Icon>
          <span>Automate deposits from your bank account.</span>
        </CardContent>
      </Card>
      <Card id='card' className='mt-4 max-w-96'>
        <CardContent className='flex w-full gap-4'>
          <Icon>vertical_align_top</Icon>
          <span>Top up emergency savings until you reach your target.</span>
        </CardContent>
      </Card>
      <Card id='card' className='mt-4 max-w-96'>
        <CardContent className='flex w-full gap-4'>
          <Icon>vertical_align_top</Icon>
          <span>
            Contribute to your TFSA until you reach your{' '}
            <Money value={36000} canHide={false} /> contribution limit for the
            year.
          </span>
        </CardContent>
      </Card>
    </div>
  )
}

function JustFuckingStart() {
  const ref = useRef(null)

  const { scrollY } = useScroll({
    target: ref,
    offset: ['start end', 'end start']
  })

  const rotation = useTransform(scrollY, (v) => -v / 3)
  const x = useTransform(scrollY, (v) => {
    const leftMargin = Math.max(window.innerWidth - 1024, 0) / 2
    return v / 3 + leftMargin - 700
  })

  return (
    <motion.section ref={ref} className='bg-black py-20 sm:py-40'>
      <div className='flex w-full flex-col justify-center overflow-x-hidden bg-black p-4'>
        <div
          className={cn(
            'mx-auto flex w-full max-w-5xl flex-col font-black uppercase leading-none text-white',
            'text-[4rem] leading-[3.5rem]',
            'sm:text-[8rem] sm:leading-[7rem]',
            'lg:text-[10rem] lg:leading-[9rem]'
          )}
        >
          <span>Just</span>
          <div className='flex shrink items-center gap-0.5 sm:gap-1'>
            <span>F</span>
            <motion.svg
              style={{
                rotate: rotation
              }}
              className='size-[3rem] flex-none sm:size-[5.8rem] lg:size-[7rem]'
              viewBox='0 0 122 120'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M44.3851 119.814L47.7082 83.261L17.3856 104.445L0.770508 75.3689L34.0007 59.9999L0.770508 44.6309L17.3856 15.5545L47.7082 36.7388L44.3851 0.185547H77.6153L74.2923 36.7388L104.615 15.5545L121.23 44.6309L87.9998 59.9999L121.23 75.3689L104.615 104.445L74.2923 83.261L77.6153 119.814H44.3851Z'
                fill='#FFFFFF'
              />
            </motion.svg>
            <span>cking</span>
          </div>
        </div>
        <div
          className={cn(
            'flex w-full flex-col font-black uppercase leading-none text-white',
            'text-[4rem] leading-[3.5rem]',
            'sm:text-[8rem] sm:leading-[7rem]',
            'lg:text-[10rem] lg:leading-[9rem]'
          )}
        >
          <motion.span initial={{ x: 0 }} style={{ x: x }}>
            start
          </motion.span>
        </div>
        <p className='mx-auto mt-6 max-w-prose text-2xl text-white'>
          You don&apos;t need a complicated plan to start investing. All you
          need to succeed is low fees, tax optimisation, and lots and lots of
          time. Stop procrastinating. Stop worrying. Start with an emergency
          savings and tax free investment account today.
        </p>
      </div>
      <div className='mx-auto mt-10 flex w-full max-w-5xl px-4'>
        <Router
          href={route('login')}
          className='flex w-full items-center justify-center rounded-full bg-white p-6 text-center hover:bg-slate-200 sm:max-w-max sm:px-20'
        >
          <span className='mx-auto text-lg font-medium leading-none text-strong lg:text-xl'>
            Start now
          </span>
        </Router>
      </div>
    </motion.section>
  )
}

function EmergencySavingsHeader() {
  return (
    <section className='bg-green-500 p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-10 text-pretty'>
        <div className='flex size-16 sm:size-24'>
          <div className='h-full w-1/2 rounded-bl-full rounded-tr-full bg-green-600' />
          <div className='h-full w-1/2 rounded-br-full rounded-tl-full bg-green-300' />
        </div>
        <h3 className='flex flex-col text-center text-5xl font-black uppercase leading-none text-strong sm:text-7xl sm:leading-none'>
          <span>emergency</span>
          <span>savings</span>
        </h3>
        <p className='mx-auto max-w-prose text-center text-2xl text-green-900'>
          Life can be unpredictable. When you need some money urgently the last
          thing you should do is sell your long term investments or take on
          debt. Having an emergency savings fund is a critical buffer that gives
          you peace of mind.
        </p>
      </div>
    </section>
  )
}

function EmergencySavingsAssess() {
  const [amount, setAmount] = useState<number>(20000)

  const total = useMemo(() => amount * 3, [amount])
  return (
    <section className='bg-green-100 p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-10 text-pretty sm:flex-row sm:gap-24'>
        <div className='flex flex-col gap-10'>
          <h4 className='text-3xl font-medium text-strong sm:text-5xl'>
            Fynbos helps you assess your financial situation and set clear
            savings targets
          </h4>
          <p className='text-xl'>
            Use the handy calculator to work out your optimal emergency savings
            target.
          </p>
        </div>
        <Card role='presentation' className='w-full gap-8 p-6'>
          <h5 className='text-xl font-medium text-strong'>
            How much do you spend each month?
          </h5>
          <p>
            Add up any unavoidable monthly expenses that you&apos;d still have
            to pay if you had no income.
          </p>
          <TextField
            prefix='R'
            type='number'
            value={amount}
            onChange={(e) => setAmount(Number(e.target.value))}
          />
          <div className='flex w-full flex-col gap-4'>
            <h5 className='text-lg font-medium'>
              Your emergency savings target amount
            </h5>
            <p>
              This amount should be enough to cover you for 3 months expenses.
            </p>
            <div className='flex w-full rounded-xl bg-green-400 px-4 py-3 text-lg font-medium text-green-900'>
              <Money value={total} maximumFractionDigits={0} canHide={false} />
            </div>
          </div>
        </Card>
      </div>
    </section>
  )
}

function EmergencySavingsAutomate() {
  return (
    <section className='bg-slate-50 p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col-reverse items-center justify-center gap-10 text-pretty sm:flex-row sm:gap-24'>
        <div role='presentation' className='flex w-full flex-col gap-4'>
          <Card className='flex-row gap-4 bg-slate-200 py-8 pl-4 pr-6'>
            <Icon className='size-10 text-4xl'>vertical_align_top</Icon>
            <span>
              Top up your emergency savings until you reach your target.
            </span>
          </Card>
          <Card className='flex-row gap-4 bg-slate-200 py-8 pl-4 pr-6'>
            <Icon className='size-10 text-4xl'>vertical_align_top</Icon>
            <span>
              Contribute to TFSA until you reach your R36k contribution limit
              for the year.
            </span>
          </Card>
        </div>
        <div className='flex flex-col gap-10'>
          <h4 className='text-3xl font-medium text-strong sm:text-5xl'>
            Automate regular contributions to a low-risk, accessible savings
            account
          </h4>
          <p className='text-xl'>
            Set up automatic transfers through the Fynbos platform. All
            emergency savings funds are invested directly with Allan Gray.
          </p>
          <AllanGray />
        </div>
      </div>
    </section>
  )
}

function EmergencySavingsTrack() {
  const [amount, setAmount] = useState<number>(20000)
  const [percentage, setPercentage] = useState<number>(0.4)

  const _onChangeAmount = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setAmount(Number(event.target.value))
    },
    []
  )

  const _onChangePercent = useCallback<(value: number[]) => void>((val) => {
    const percent = Number(val[0])
    setPercentage(percent)
  }, [])

  return (
    <section className='bg-slate-100 p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-10 text-pretty sm:flex-row sm:gap-24'>
        <div className='flex flex-col gap-10'>
          <h4 className='text-3xl font-medium text-strong sm:text-5xl'>
            Track your progress and stay on target
          </h4>
          <p className='text-xl'>
            You can easily track your progress and adjust any amounts if your
            financial situation changes.
          </p>
        </div>
        <Card role='presentation' className='w-full gap-4 p-6'>
          <div className='flex gap-1'>
            <Rings
              size='size-24'
              backgroundColour={cn('text-slate-100')}
              primary={{
                colour: 'text-green-400',
                percentage: percentage
              }}
            >
              <div className='absolute flex flex-col items-center justify-center'>
                <EmergencySavingsLogo />
              </div>
            </Rings>
            <div className='flex items-center gap-1 text-xl font-medium'>
              <span className='text-green-500'>
                {(percentage * 100).toFixed(0)}%
              </span>
              of <Money value={amount} canHide={false} />
            </div>
          </div>
          <h5 className='text-lg font-medium'>Emergency savings target</h5>
          <TextField
            prefix='R'
            type='number'
            value={amount}
            onChange={_onChangeAmount}
          />
          <Slider
            id='percentage-slider'
            colour={Colours.green}
            className='relative flex h-10 w-full touch-none select-none items-center'
            value={[percentage]}
            onValueChange={_onChangePercent}
            max={1}
            min={0}
            step={0.01}
          />
        </Card>
      </div>
    </section>
  )
}

function EmergencySavingsRemind() {
  return (
    <section className='bg-white p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col-reverse items-center justify-center gap-10 text-pretty sm:flex-row sm:gap-24'>
        <div role='presentation' className='flex w-full flex-col gap-4'>
          <Card className='bg-slate-50 p-6'>
            <span>
              Your next debit order of{' '}
              <Money value={500} className='font-medium' /> will run on the{' '}
              <span className='font-medium'>1st of January</span>, from your
              bank account <span className='font-medium'>ending 4502</span>.
            </span>
            <span className='ml-auto text-disabled'>15:00</span>
            <span className='my-4 h-px bg-slate-200'></span>
            <span className='flex items-center justify-center gap-4 text-xl font-medium text-success'>
              <Icon>open_in_new</Icon>View your progress
            </span>
          </Card>
        </div>
        <div className='flex flex-col gap-10'>
          <h4 className='text-3xl font-medium text-strong sm:text-5xl'>
            Receive reminders to ensure consistent contributions
          </h4>
          <p className='text-xl'>
            You will be notified on WhatsApp for any important information.
          </p>
        </div>
      </div>
    </section>
  )
}

function TaxFreeHeader() {
  return (
    <section className='bg-sky-400 p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-10 text-pretty'>
        <div className='flex size-16 flex-wrap sm:size-24'>
          <div className='h-1/2 w-1/2 rounded-full rounded-tr-none bg-sky-500' />
          <div className='h-1/2 w-1/2 rounded-full rounded-br-none bg-sky-200' />
          <div className='h-1/2 w-1/2 rounded-full rounded-tl-none bg-sky-600' />
          <div className='h-1/2 w-1/2 rounded-full rounded-bl-none bg-sky-300' />
        </div>
        <h3 className='flex flex-col text-center text-5xl font-black uppercase leading-none text-strong sm:text-7xl sm:leading-none'>
          <span>Tax-free</span>
          <span>investing</span>
        </h3>
        <p className='mx-auto max-w-prose text-center text-2xl text-sky-900'>
          A Tax Free Savings Account (TFSA) is a special account where you can
          invest without paying tax on your earnings. Everybody under 35 must
          invest in a TFSA for the long term. That&apos;s it.
        </p>
      </div>
    </section>
  )
}

function TaxFreeLong() {
  const [age, setAge] = useState<number[]>([18])

  const INTEREST_RATE = 0.094 // 9.4%
  const MAX_YEAR = 65
  const MIN_YEAR = 18

  const total = useMemo(() => {
    return calculateTFSAFutureValue(3000, INTEREST_RATE, MAX_YEAR - MIN_YEAR)
  }, [])

  const ageTotal = useMemo(() => {
    return calculateTFSAFutureValue(3000, INTEREST_RATE, MAX_YEAR - age[0])
  }, [age])

  const percentage = useMemo(() => {
    return (ageTotal / total) * 100
  }, [total, ageTotal])
  return (
    <section className='bg-sky-100 p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-10 text-pretty sm:flex-row sm:gap-24'>
        <div className='flex w-full flex-col gap-10'>
          <h4 className='text-3xl font-medium text-strong sm:text-5xl'>
            Tax-free investing is for the long run
          </h4>

          <p className='text-xl'>
            A TFSA is for investing for the long term because it allows your
            investments to grow tax-free over time, maximizing compounding gains
            and long-term wealth.
          </p>
        </div>
        <Card role='presentation' className='w-full gap-8 p-6'>
          <h5 className='text-xl font-medium text-strong'>
            How much will you have at 65 if you maximise your TFSA?
          </h5>
          <div className='flex w-full justify-center text-3xl font-medium text-sky-500 sm:text-5xl'>
            <Money value={ageTotal} maximumFractionDigits={0} canHide={false} />
          </div>
          <div className='flex w-full flex-col items-end'>
            <span className='text-xs'>
              <Money value={total} maximumFractionDigits={0} canHide={false} />
            </span>
            <div className='mb-1 mr-0 h-4 w-px bg-slate-700' />
            <div className='flex h-6 w-full overflow-hidden rounded-full bg-rose-500'>
              <motion.div
                style={{ width: `${percentage}%` }}
                className={cn('h-full bg-sky-400')}
              />
            </div>
          </div>
          <p className='font-medium'>
            Slide to the age you start investing in your TFSA.
          </p>
          <SliderPrimitive.Root
            className='relative flex h-5 w-full touch-none select-none items-center'
            value={age}
            onValueChange={(age) => setAge(age)}
            max={MAX_YEAR}
            min={MIN_YEAR}
            step={1}
          >
            <SliderPrimitive.Track className='relative h-2 grow rounded-full bg-slate-200'>
              <SliderPrimitive.Range className='absolute h-full rounded-full bg-slate-200' />
            </SliderPrimitive.Track>
            <SliderPrimitive.Thumb asChild aria-label='Age'>
              <div className='flex rounded-lg bg-slate-500 px-6 py-1 text-base text-white focus:outline-none'>
                <span>{age[0]}</span>
              </div>
            </SliderPrimitive.Thumb>
          </SliderPrimitive.Root>

          <p className='text-sm'>
            Assumes a{' '}
            <Money value={3000} maximumFractionDigits={0} canHide={false} />{' '}
            monthly investment up to the maximum lifetime contribution of{' '}
            <Money value={500000} maximumFractionDigits={0} canHide={false} />{' '}
            and a {INTEREST_RATE * 100}% annual return, equal to the MSCI World
            index over the last 40 years.
          </p>
        </Card>
      </div>
    </section>
  )
}

function TaxFreeBest() {
  return (
    <section className='bg-slate-50 p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col-reverse items-center justify-center gap-10 text-pretty sm:flex-row sm:gap-24'>
        <div
          role='presentation'
          className='grid w-full min-w-max grid-cols-2 gap-4'
        >
          <Card className='col-span-1 w-full py-4'>
            <Sygnia />
          </Card>
          <Card className='col-span-1 py-4'>
            <AllanGray />
          </Card>
          <Card className='col-span-1 w-full py-4'>
            <OneNvest />
          </Card>
          <Card className='col-span-1 py-4'>
            <Satrix />
          </Card>
          <Card className='col-span-1 w-full py-4'>
            <TenX />
          </Card>
          <Card className='col-span-1 py-4'>
            <FNB />
          </Card>
        </div>
        <div className='flex  flex-col gap-10'>
          <h4 className='text-3xl font-medium text-strong sm:text-5xl'>
            Invest with the best
          </h4>
          <p className='text-xl'>
            Fynbos provides access to the top tier funds with no platform fees
            and the lowest brokerage fees in the industry ensuring you get the
            best returns and don&apos;t waste your money on fees.
          </p>
        </div>
      </div>
    </section>
  )
}

function TaxFreeGuard() {
  const [fieldAmount, setFieldAmount] = useState<string>()
  const [amount, setAmount] = useState<number>(3000)
  const [error, setError] = useState<string>()

  const percentage = useMemo(() => {
    return amount / 36000
  }, [amount])

  const _onChangeAmount = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const original = 3000
      const fieldAmount = Number(event.target.value)
      setFieldAmount(event.target.value)

      const total = fieldAmount + original
      if (total > 36000) {
        setError('You are contributing more than your annual limit')
      } else {
        setAmount(total)
        setError('')
      }
    },
    []
  )
  return (
    <section className='bg-slate-100 p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-10 text-pretty sm:flex-row sm:gap-24'>
        <div className='flex flex-col gap-10'>
          <h4 className='text-3xl font-medium text-strong sm:text-5xl'>
            Guard rails to keep you on track
          </h4>
          <p className='text-xl'>
            Fynbos monitors your TFSA contributions to ensure you&apos;re
            getting the maximum value without any risk of exceeding your annual
            and lifetime limits.
          </p>
        </div>
        <Card role='presentation' className='w-full gap-4 p-6'>
          <h5 className='text-xl font-medium text-strong'>
            Your TFSA progress
          </h5>
          <p>Current tax year 1 March 2024 to 28 February 2025.</p>
          <div className='flex gap-1'>
            <Rings
              size='size-24'
              backgroundColour={cn('text-slate-100')}
              primary={{
                colour: 'text-sky-400',
                percentage: percentage
              }}
            >
              <div className='absolute flex flex-col items-center justify-center'>
                <TFSALogo />
              </div>
            </Rings>
            <div className='flex flex-col justify-center gap-1'>
              <div className='flex w-full justify-center text-2xl font-medium text-sky-500 sm:text-4xl'>
                <Money value={amount} canHide={false} />
              </div>
              <span>
                of <Money value={36000} canHide={false} />
              </span>
            </div>
          </div>
          <div className='mt-4 flex w-full flex-col gap-4'>
            <h5 className='text-lg font-medium'>Contributed elsewhere</h5>
            <p>
              Any money you&apos;ve contributed elsewhere that you have put
              towards your tax-free investments.
            </p>
          </div>
          <TextField
            prefix='R'
            type='number'
            value={fieldAmount}
            onChange={_onChangeAmount}
            errorMessage={error}
          />
        </Card>
      </div>
    </section>
  )
}

function StrongFoundations() {
  return (
    <section className='bg-purple-600 p-4 py-20 text-white sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-10 text-pretty'>
        <h3 className='flex flex-col text-center text-5xl font-black uppercase leading-none sm:text-7xl sm:leading-none'>
          <span>Built on strong</span>
          <span>foundations</span>
        </h3>
        <p className='mx-auto max-w-prose text-center text-2xl'>
          Fynbos makes it easy for young South Africans to build, track and
          manage their financial plan.
        </p>
        <div className='flex flex-col gap-4 sm:flex-row'>
          <div className='flex w-full flex-col gap-4 rounded-card bg-purple-800 p-6 sm:gap-10 sm:p-10'>
            <h4 className='text-2xl font-medium sm:text-4xl'>
              Proven strategies for long-term growth
            </h4>
            <p className='text-xl sm:text-2xl'>
              Our platform uses a tried-and-trusted methodology of low fee,
              tax-free investing paired with a high yield emergency savings
              account.
            </p>
          </div>
          <div className='flex w-full flex-col gap-4 rounded-card bg-purple-800 p-6 sm:gap-10 sm:p-10'>
            <h4 className='text-2xl font-medium sm:text-4xl'>
              Trusted partnerships and expert guidance
            </h4>
            <p className='text-xl sm:text-2xl'>
              We make it simple to start investing without needing to understand
              all the jargon or build a complicated personal finance plan or
              budget.
            </p>
          </div>
          <div className='flex w-full flex-col gap-4 rounded-card bg-purple-800 p-6 sm:gap-10 sm:p-10'>
            <h4 className='text-2xl font-medium sm:text-4xl'>
              Commitment to quality, simplicity, and transparency
            </h4>
            <p className='text-xl sm:text-2xl'>
              Our easy-to-use platform makes getting started stupidly simple,
              and our fees are all 100% transparent. You really might start to
              love investing.
            </p>
          </div>
        </div>
        <Router
          href={route('learn')}
          className='flex w-full items-center justify-center rounded-full bg-yellow-400 p-6 text-center hover:bg-yellow-500'
        >
          <span className='mx-auto text-2xl font-bold uppercase leading-none text-purple-900 lg:text-4xl'>
            Click here to Learn more
          </span>
        </Router>
      </div>
    </section>
  )
}

function Pricing() {
  return (
    <section id='pricing' className='bg-white p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-10 text-pretty'>
        <h3 className='flex flex-col text-center text-5xl font-black uppercase leading-none text-strong sm:text-7xl sm:leading-none'>
          <span>pricing</span>
        </h3>
        <div className='flex flex-col gap-10 sm:flex-row'>
          <div className='flex w-full flex-col gap-6'>
            <h4 className='text-2xl font-medium sm:text-4xl'>
              Roots <span className='text-success'>free</span>
            </h4>
            <p className='text-lg sm:text-xl'>
              With the free Roots plan, there&apos;s no subscription fee. Every
              cent goes directly into your portfolio, giving you everything you
              need to start investing.
            </p>
            <h5 className='text-lg font-medium text-strong sm:text-xl'>
              What&apos;s included
            </h5>
            <div className='flex flex-col gap-4'>
              <div className='flex gap-4'>
                <Icon className='text-success'>check</Icon>
                <p className='text-lg sm:text-xl'>
                  An emergency savings account
                </p>
              </div>
              <div className='flex gap-4'>
                <Icon className='text-success'>check</Icon>
                <p className='text-lg sm:text-xl'>
                  A Tax Free Savings Account (TFSA)
                </p>
              </div>
              <div className='flex gap-4'>
                <Icon className='text-success'>check</Icon>
                <p className='text-lg sm:text-xl'>
                  Personalised goal tracking and automated allocation of your
                  contributions
                </p>
              </div>
            </div>

            <Router
              href={route('login')}
              className='flex w-full items-center justify-center rounded-full bg-green-500 p-6 text-center hover:bg-green-600 sm:max-w-max sm:px-20'
            >
              <span className='mx-auto text-lg font-medium leading-none text-on-color lg:text-xl'>
                Start for free
              </span>
            </Router>
          </div>
          <div className='flex w-full flex-col gap-6'>
            <h4 className='text-2xl font-medium sm:text-4xl'>
              Protea <span className='text-disabled'>(coming soon)</span>
            </h4>
            <p className='text-lg sm:text-xl'>
              Get a complete savings and investment portfolio in one place for a
              simple monthly subscription, with everything from the Roots plan
              plus more.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

function calculateTFSAFutureValue(
  monthlyContribution: number,
  annualInterestRate: number,
  years: number
): number {
  const maxTotalContribution = 500000
  const months = years * 12
  const monthlyInterestRate = annualInterestRate / 12

  let futureValue = 0
  let totalContribution = 0

  for (let month = 1; month <= months; month++) {
    // Add the monthly contribution if total contribution is below the maximum allowed
    if (totalContribution < maxTotalContribution) {
      totalContribution += monthlyContribution
      futureValue += monthlyContribution
    }

    // Apply the monthly interest rate
    futureValue *= 1 + monthlyInterestRate

    // Ensure total contribution does not exceed the max total contribution
    if (totalContribution > maxTotalContribution) {
      totalContribution = maxTotalContribution
    }
  }

  return futureValue
}
